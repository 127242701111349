@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fjalla+One&display=swap');

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 240 10% 3.9%;
    --card: 0 0% 100%;
    --card-foreground: 240 10% 3.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 240 10% 3.9%;
    --primary: 218 96% 19%;
    --primary-foreground: 0 0% 98%;
    --secondary: 240 4.8% 95.9%;
    --secondary-foreground: 218 96% 19%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;
    --muted: 240 4.8% 95.9%;
    --muted-foreground: 240 3.8% 46.1%;
    --accent: 240 4.8% 95.9%;
    --accent-foreground: 218 96% 19%;
    --border: 240 5.9% 90%;
    --input: 240 5.9% 90%;
    --ring: 218 96% 19%;
    --radius: 0.5rem;
  }

  .dark {
    --background: #16191D;
    --foreground: 210 40% 98%;
    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;
    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;
    --primary: 166 56% 50%;
    --primary-foreground: 222.2 47.4% 11.2%;
    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;
    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;
    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;
    --border: 217.2 32.6% 17.5%;
    --input: 210 6% 34%;
    --ring: 224.3 76.3% 48%;
  }
}


@layer base {
  * {
    @apply border-border;
    @apply text-[#CAD2DA]
  }

  body {
    @apply bg-background text-foreground;
    font-family: "Poppins";
  }
}

/* Hide number spinner for input type number in Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

/* Hide number spinner for input type number in Chrome, Safari, and Edge */
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Hide number spinner for input type number in other browsers */
input[type='number'] {
  appearance: textfield;
}

@media screen and (max-width: 767px) {
  input,
  select {
    font-size: 16px !important;
  }
}
